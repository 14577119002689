<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="usersHeaders"
          :items="items"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>المستخدمين</v-toolbar-title>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <!-- <v-btn to="posts/add" text class="pa-0"> -->
            <v-icon
              color="primary"
              small
              class="mr-2"
              @click="updateItem(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- </v-btn> -->

            <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>

            <!-- <v-icon
              color="error"
              small
              class="mr-2"
              @click="goToInstructors(item)"
            >
              mdi-plus
            </v-icon> -->
            <!-- delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogNotification" max-width="500px">
              <v-card>
                <v-card-title class="headline">send notification</v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          dir="rtl"
                          v-model="arbody"
                          :rules="textRules"
                          label="Notification Body Ar"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          dir="rtl"
                          v-model="enbody"
                          :rules="textRules"
                          label="Notification Body En"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          dir="rtl"
                          v-model="trbody"
                          :rules="textRules"
                          label="Notification Body Tr"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeNotificationDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="sendNotification()"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
        <v-form ref="form" v-model="valid" style="padding:50px">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="signupForm.first_name"
                :rules="textRules"
                label="الاسم الأول"
                prepend-icon="mdi-account"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="signupForm.last_name"
                :rules="textRules"
                label="الاسم الأخير"
                prepend-icon="mdi-lock"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="signupForm.email"
                :rules="emailRules"
                label="البريد الإلكتروني"
                prepend-icon="mdi-email"
                required
              ></v-text-field>
            </v-col>

            <v-col md="6">
              <v-autocomplete
                :loading="loadingRoles"
                v-model="signupForm.role"
                :items="roles"
                dense
                chips
                small-chips
                label="القواعد"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="signupForm.password"
                :rules="textRules"
                :type="showPassword ? 'text' : 'password'"
                label="كلمة المرور"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="signupForm.confirmPassword"
                :rules="confirmPasswordRules"
                :type="showPassword ? 'text' : 'password'"
                label="تأكيد كلمة المرور"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
            </v-col>

            <v-col md="12" v-if="signupForm.role == 4">
              <!-- <quill-editor
                v-model="signupForm.about"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              /> 
              <v-textarea
                v-model="signupForm.about"
                counter
                label="عن"
              ></v-textarea>-->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions style="display:flex;justifyContent:center">
        <v-btn color="error darken-1" @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" @click="submit">
          <v-progress-circular
            class="mr-2"
            color="primary"
            indeterminate
            size="25"
            v-if="submitted"
          ></v-progress-circular>
          إرسال
        </v-btn>
        <v-icon
          color="primary"
          large
          class="mr-2"
          @click="sendNotificationModal()"
        >
          mdi-email
        </v-icon>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  // props: ["record", "dialog"],
  data() {
    return {
      arbody: null,
      enbody: null,
      trbody: null,
      record: null,
      dialogDelete: false,
      id: null,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      loadingRoles: false,
      error: "",
      openDialog0: false,
      snackbarMsg: null,
      snackbarColor: "success",
      formTitle: "Creat user",
      showPassword: false,
      roles: [],
      loadingUsers: false,
      items: [],
      dialogNotification: false,
      editorOption: {
        // some quill options
      },
      signupForm: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: 1,
      },
      cycle: false,
      usersHeaders: [
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      textRules: [(v) => !!v || "field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "E-mail must be valid";
        },
      ],
      confirmPasswordRules: [
        (v) => !!v || "Confirm Password is required",
        (v) => v === this.signupForm.password || "password must be matched",
      ],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    ...mapActions(["login", "signup"]),
    // goToInstructors(item) {
    //   this.$router.push(`/instructor/add/${item.id}`);
    // },
    // dialogOpen(item, id) {
    //   this.record = item;
    //   this.id = id;
    //   this.openDialog0 = true;
    // },
    // async initPayment() {
    //   const params = new URLSearchParams();
    //   params.append("InvoiceAmount", 100);

    //   const config = {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   };

    //   axios
    //     .post("http://mobarmg.com/InitiatePayment.php", params, config)
    //     .then((result) => {
    //       // Do somthing
    //       console.log(result.data);
    //     })
    //     .catch((err) => {
    //       // Do somthing
    //     });
    // },
    // async executePayment() {
    //   const params = new URLSearchParams();
    //   params.append("PaymentMethodId", 2);
    //   params.append("name", "ramy");
    //   params.append("MobileCountryCode", "965");
    //   params.append("CustomerMobile", "1111222333");
    //   params.append("CustomerEmail", "user@gmail.com");
    //   params.append("InvoiceValue", 100);
    //   params.append("DisplayCurrencyIso", "KWD");
    //   params.append("CallBackUrl", "https://yoursite.com/success");
    //   params.append("ErrorUrl", "https://yoursite.com/error");
    //   params.append("ItemName", "4talents course");
    //   params.append("Quantity", 1);
    //   params.append("UnitPrice", 100);

    //   const config = {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   };

    //   axios
    //     .post("http://mobarmg.com/executePayment.php", params, config)
    //     .then((result) => {
    //       // Do somthing
    //       console.log(result.data);
    //     })
    //     .catch((err) => {
    //       // Do somthing
    //     });
    // },
    sendNotificationModal(record) {
      this.dialogNotification = true;
    },
    async sendNotification() {
      items.forEach(async (element) => {
        if (element.notification_token) {
          const { data } = await ApiService.get(
            `custom/sendNotification?InvoiceAmount=${
              element.notification_token
            }&body=${
              element.lang == "ar"
                ? this.arbody
                : element.lang == "tr"
                ? this.trbody
                : this.enbody
            }`
          );
          this.dialogNotification = false;
        }
      });
    },
    closeNotificationDelete() {
      this.dialogNotification = false;
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          delete this.signupForm["confirmPassword"];
          this.signupForm["status"] = "active";
          if (this.signupForm.id) {
            const userReq = await ApiService.update(
              "users",
              this.signupForm.id,
              this.signupForm
            );
          } else {
            const authSignup = await this.signup(this.signupForm);
            if (this.userGetter.errorSingup) {
              this.snackbarMsg = this.userGetter.errorSingup;
              this.snackbar = true;
            }
          }
          this.getUsers();
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.snackbarMsg = msg;
          this.snackbar = true;
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.signupForm.first_name = null;
      this.signupForm.last_name = null;
      this.signupForm.email = null;
      this.signupForm.password = null;
      this.signupForm.confirmPassword = null;
      this.signupForm.role = 1;
    },
    updateItem(item) {
      Object.keys(this.signupForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.signupForm[element] = item[element].id;
          } else {
            this.signupForm[element] = item[element];
          }
        }
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        const { data } = await ApiService.delete(`users/${this.record.id}`);
        this.loading = false;
        this.closeDelete();
        let msg;
        msg = "deleted successfully";
        // }
        this.snackbarMsg = msg;
        this.snackbar = true;
        this.getUsers();
      } catch (error) {
        console.log(error);
      }
    },
    async getRoles() {
      try {
        this.loadingRoles = true;
        // &filter[group_id.question_id.result_id.parent_id][empty]
        const { data } = await ApiService.get(`roles`);
        this.roles = data.data;
        console.log(this.roles);
        this.loadingRoles = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getUsers() {
      try {
        this.loadingUsers = true;
        // &filter[group_id.question_id.result_id.parent_id][empty]
        const { data } = await ApiService.get(`users`);
        this.items = data.data;
        this.loadingUsers = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(["userGetter"]),
  },

  mounted() {
    this.getRoles();
    this.getUsers();
    // this.record && Object.assign(this.loginForm, this.record);
    // Object.assign(this.initialPayload, this.record);
  },
};
</script>

<style lang="scss" scoped>
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
